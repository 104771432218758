import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

import { RichText } from 'prismic-reactjs';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import SliceZone from '../components/SliceZone';
import HeroCarousel from '../components/HeroCarousel';
import BorderTitle from '../components/BorderTitle';

import { breakpoint } from '../theme';
import ProjectItem from '../components/ProjectItem';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(80)};
`;

const Item = styled(Col)`
  margin: 0 0 ${rem(20)};

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

const IntroSection = styled(Row)`
  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
    margin-bottom: ${rem(75)};
  }
`;

const IntroContent = styled(Col)`
  text-align: center;
  width: 100%;
  max-width: ${rem(500)};
  margin: 0 auto;
  font-size: ${rem(18)};

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(680)};
    font-size: ${rem(20)};
  }

  &:after {
    content: '';
    display: block;
    height: 4px;
    width: ${rem(60)};
    margin: ${rem(30)} auto 0;
    background-color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const DescriptionSection = styled(Row)`
  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
    margin-bottom: ${rem(75)};
  }
`;

const RelatedSection = styled(Row)`
  margin-top: ${rem(50)};

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: 1.2rem;
  font-size: ${rem(18)};

  ${breakpoint('medium')} {
    font-size: ${rem(22)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(24)};
  }
`;

const DescriptionContent = styled(Col)`
  border-top: 1px solid ${(props) => props.theme.global.border.color};
  padding-top: ${rem(40)};
  padding-bottom: ${rem(16)};

  ${breakpoint('mediumlarge')} {
    padding-top: ${rem(40)};
    width: 50%;
  }

  h2,
  h3 {
    font-size: ${rem(17)};

    ${breakpoint('medium')} {
      font-size: ${rem(20)};
    }

    ${breakpoint('large')} {
      font-size: ${rem(22)};
    }
  }

  h4,
  h5,
  h6 {
    font-size: ${rem(15)};

    ${breakpoint('medium')} {
      font-size: ${rem(18)};
    }

    ${breakpoint('large')} {
      font-size: ${rem(20)};
    }
  }
`;

function Product({ data, pageContext }) {
  const { t } = useTranslation();
  useLangRedirect(pageContext);

  const { product: page } = data.prismic;

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      {page.hero_items && page.hero_items.length ? (
        <HeroCarousel items={page.hero_items} />
      ) : (
        <Hero
          preHeading={t('product')}
          heading={RichText.asText(page.title)}
          image={page.product_imageSharp || page.featured_imageSharp}
          pageTitle
          big
        >
          {page.hero_text && <RichText render={page.hero_text} />}
        </Hero>
      )}
      {page.short_description && (
        <IntroSection>
          <IntroContent>
            <RichText render={page.short_description} />
          </IntroContent>
        </IntroSection>
      )}
      <Container>
        {(page.description || page.details) && (
          <DescriptionSection>
            <DescriptionContent>
              {page.description && (
                <div>
                  <SectionTitle>Information</SectionTitle>
                  <RichText render={page.description} />
                </div>
              )}
            </DescriptionContent>
            <DescriptionContent>
              {page.details && <RichText render={page.details} />}
            </DescriptionContent>
          </DescriptionSection>
        )}
        <Row>
          <Col>
            <SliceZone slices={page.body} />
          </Col>
        </Row>
        {page.related_projects.length && page.related_projects[0].project && (
          <RelatedSection>
            <Col>
              <BorderTitle as="h4">{t('relatedProjects')}</BorderTitle>
            </Col>
            {page.related_projects.map((item) => (
              // eslint-disable-next-line no-underscore-dangle
              <Item key={item.project._meta.uid}>
                <ProjectItem data={item.project} />
              </Item>
            ))}
          </RelatedSection>
        )}
      </Container>
    </Layout>
  );
}

Product.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismic {
      product(uid: $uid, lang: $lang) {
        body {
          ... on PRISMIC_ProductBodyQuote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_ProductBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_ProductBodyImage {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on PRISMIC_ProductBodyImage_gallery {
            type
            label
            primary {
              name_of_the_gallery
            }
            fields {
              gallery_image
              gallery_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1000, maxHeight: 850, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image_captions
            }
          }
        }
        short_description
        related_projects {
          project {
            _linkType
            ... on PRISMIC_Project {
              title
              featured_video
              featured_image
              featured_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 580, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              _linkType
              _meta {
                id
                lang
                type
                uid
              }
            }
          }
        }
        description
        details
        product_image
        product_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        seo_meta_title
        seo_description
        hero_text
        hero_title
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        _meta {
          uid
          lang
          type
        }
      }
    }
  }
`;

export default Product;
